import './About.css';
import {
    DiJavascript,
    DiHtml5,
    DiCss3,
    DiNodejsSmall,
    DiBootstrap,
    DiGithub,
    DiReact
} from 'react-icons/di';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import VizibilitySensor from 'react-visibility-sensor';
import { useState } from 'react';

const TechCard = () => {
    const [cardVisible, setCardVisible] = useState(false);

    const handleChange = (isVisible) => {
        setCardVisible(isVisible)
    }

    return (
        <VizibilitySensor partialVisibility onChange={(isVisible) => {handleChange(isVisible)}}>
            <Fade in={cardVisible} style={{transitionDelay: "50ms"}}>
                <Card className="about__card">
                    <CardContent className="about__tech-card__content">
                        <div className="about__tech">
                            <h3>Languages:</h3>
                            <p>javascript, html, css, sass, sql</p>
                            <h3>Development Tools:</h3>
                            <p>node.js, react.js, express.js, firebase, material-ui, progresql, bootstrap, reach router</p>
                            <div className="about__icon-container">
                                <DiJavascript size="4rem" color="#e06f2c" />
                                <DiCss3 size="4rem" color="#e06f2c" />
                                <DiHtml5 size="4rem" color="#e06f2c" />
                                <DiReact size="4rem" color="#e06f2c" />
                                <DiBootstrap size="4rem" color="#e06f2c" />
                                <DiNodejsSmall size="4rem" color="#e06f2c" />
                                <DiGithub size="4rem" color="#e06f2c" />
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Fade>
        </VizibilitySensor>
    )
}

export default TechCard;