import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import VisibilitySensor from 'react-visibility-sensor';
import { useState } from 'react';
import './About.css';

const RightNowCard = () => {
    const [cardVisible, setCardVisible] = useState(false);

    const handleChange = (isVisible) => {
        setCardVisible(isVisible)
    }

    return (
        <VisibilitySensor partialVisibility onChange={(isVisible) => {handleChange(isVisible)}}>
            <Fade in={cardVisible} style={{ transitionDelay: "50ms"}}>
                <Card className="about__card">
                    <CardContent className="about__right-now-card__content">
                        <div className="about__right-now">
                            <h2 className="about__right-now-title">right now...</h2>
                            <p className="about__right-now-text">
                                I have recently graduated from a software development bootcamp
                                with Northcoders in Manchester. This was a fantastic course and has
                                given me a wealth of experience with a number of key industry
                                skills, including version control with github, pair-programming and
                                remote collaboration, best practice coding, and many more.
                            </p>
                        </div>
                    </CardContent>
                </Card>
            </Fade>
        </VisibilitySensor>
    )
}

export default RightNowCard;