import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import './Nav.css';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e06f2c',
        },
        secondary: {
            main: '#273537',
        }
    }
})

const Nav = () => {

    const makeActive = (event) => {
        const buttons = [...event.target.parentElement.parentElement.parentElement.children];
        buttons.forEach(button => {
            const links = [...button.children[0].children];
            links.forEach(link => {
                link.classList.remove("nav-link--active");
            });
        });
        
        event.target.classList.add("nav-link--active");
    }

    return (
        // <div className="nav">
            <ThemeProvider theme={theme}>
                <AppBar position="sticky" color="primary">
                    <Toolbar>
                        <Button color="secondary" onClick={makeActive} >
                            <a className="nav-link" href="#home">
                                Home
                            </a>
                        </Button>
                        <Button color="secondary" onClick={makeActive} >
                            <a className="nav-link" href="#about">
                                About
                            </a>
                        </Button>
                        <Button color="secondary" onClick={makeActive} >
                            <a className="nav-link" href="#portfolio">
                                Portfolio
                            </a>
                        </Button>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        // </div>
    )

}

export default Nav;