import OrganiseMeCard from './OrganiseMeCard';
import TextConverterCard from './TextConverterCard';
import './Work.css';

const Work = () => {

    return (
        <div id="portfolio" className="work__container">
            <h1 className="work__header">My Work</h1>
            <div className="work__card-box">
                <OrganiseMeCard />
                <TextConverterCard />
            </div>
        </div>
    )
}

export default Work;