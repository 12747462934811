import { DiGithubBadge } from 'react-icons/di';
import { IoLogoLinkedin } from 'react-icons/io';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import './Landing.css';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e06f2c',
        },
    }
})

const Landing = ({toggleDrawer}) => {

    return (
        <div id="home" className="hero__container">
            <div className="hero__main">
                <p className="hero__typewriting-text">
                    &lt;hello world&gt;
                </p>
                <p className="hero__tagline">
                    I create elegant, user-focused software solutions.
                </p>
                <div className="icon-container">
                    <div className="hero__icon">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/willfs">
                            <IoLogoLinkedin size="4rem" color="#e06f2c"/>
                        </a>
                    </div>
                    <div target="_blank" rel="noopener noreferrer" className="hero__icon">
                        <a href="https://github.com/Blakjak496/">
                            <DiGithubBadge size="4rem" color="#e06f2c"/>
                        </a>
                       
                    </div>
                </div>
            </div>
            <ThemeProvider theme={theme}>
                <div className="hero__enter-container">
                <Button color="primary" >
                    <a href="#about">
                        Take a Look
                    </a>
                </Button>
                    <ArrowDownwardIcon color="primary" />
                </div>
            </ThemeProvider>
        </div>
    )
}

export default Landing;