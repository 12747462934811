import VisibilitySensor from 'react-visibility-sensor';
import Fade from '@material-ui/core/Fade';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useState } from 'react';
import './Work.css';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e06f2c',
        },
    }
})

const TextConverterCard = () => {
    const [cardVisible, setCardVisible] = useState(false);

    const handleChange = (isVisible) => {
        setCardVisible(isVisible)
    }

    return (
        <VisibilitySensor partialVisibility onChange={(isVisible) => {handleChange(isVisible)}}>
            <Fade in={cardVisible} style={{transitionDelay: "50ms"}}>
                <div className="work__card">
                    <div className="work__card-image"></div>
                    <CardContent className="work__card-content">
                        <h4>Text Converter</h4>
                        <p>A simple application for converting text to the standard format of capital letters at the start of sentences. It was primarily built for converting a block of all-caps text, but works with any text.</p>
                        <h5>Tech:</h5>
                        <p>JavaScript, ReactJS, HTML, CSS</p>
                        <ThemeProvider theme={theme}>
                            <Button color="primary" className="hero__enter-btn">
                                <a href="https://formatthisforme.netlify.app/" target="_blank" rel="noreferrer">
                                    View Project
                                </a>
                            </Button> 
                        </ThemeProvider>
                    </CardContent>
                </div>
            </Fade>
        </VisibilitySensor>
    )
}

export default TextConverterCard;