import { useState } from 'react';
import Landing from './components/landing/Landing';
import Nav from './components/nav/Nav';
import About from './components/about/About';
import Work from './components/work/Work';
import './App.css';


function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  }

  return (
    <div className="App">
      <Landing toggleDrawer={toggleDrawer} />
      <div className="app-content">
        <Nav toggleDrawer={toggleDrawer} />
        <About />
        <Work />
      </div>
      <div className="app-footer">
        <p>Lovingly Crafted by WFSmith © 2020</p>
      </div>
    </div>
  );
}

export default App;
