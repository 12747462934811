import './About.css';
import { useState } from 'react';
import TechCard from './TechCard';
import RightNowCard from './RightNowCard';

const About = () => {

    return (
        <div id="about" className="about__container">
            <div className="about__header">
                <h1 className="about__title">About Me</h1>
                <p className="about__intro">
                    Hi, I'm Will Farnell-Smith
                </p>
                <p className="about__intro">
                    I like making things that people enjoy using, so I have a keen
                    interest in UI/UX development. My designs are clean and responsive
                    whilst keeping individual personality.
                </p>
            </div>
            <TechCard />
            <RightNowCard />
            <div className="about__footer">
                <p>
                Take a look at some of my projects and don't hesitate to get in touch.
                </p>
            </div>
        </div>
    )
}

export default About;